const validatePasswordInput: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.passwordInput') || null;
    const password: string = input?.value || '';
    const isRequired: boolean = parent?.classList.contains('required');

    parent.classList.remove('invalid');

    if (isRequired) {
      const isValidPassword: boolean = getIsValidPassword(password);

      if (!isValidPassword) parent.classList.add('invalid');

      return;
    }

    if (password !== '') {
      const isValidPassword: boolean = getIsValidPassword(password);

      if (!isValidPassword) parent.classList.add('invalid');

      return;
    }
  } catch (error) {
    console.error(error);
  }
};

const clearPasswordInputError: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.passwordInput') || null;

    parent.classList.remove('invalid');
  } catch (error) {
    console.error(error);
  }
};

const setPasswordInputError: (passwordInputID: string) => void = passwordInputID => {
  try {
    const textInput: any = document.getElementById(passwordInputID);
    const parent: any = textInput?.closest('.passwordInput') || null;

    parent.classList.add('invalid');
  } catch (error) {
    console.error(error);
  }
};

const getIsValidPassword: (password: string) => boolean = password => {
  try {
    // const regex: RegExp = /^[\p{L}\d\s_&.,!@£$%():;"'-]{1,30}$/u;

    // if (!regex.test(text) || text === '') return false;

    if (password === '') return false;

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const relocatePasswordInputValueByIdToNewInputByIdAndReturnSuccess: (
  passwordInputID: string,
  newInputID: string
) => boolean = (passwordInputID, newInputID) => {
  try {
    const passwordInput: any = document.getElementById(passwordInputID);
    const newInput: any = document.getElementById(newInputID);
    const passwordInputValue: string = passwordInput?.value || '';

    newInput.value = passwordInputValue;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
